import JSZip from 'jszip';
import { WorkBook } from 'xlsx';
import { downloadFile } from './download-file';
import { $dropzone } from './elements';
import { clearError, displayError } from './error';
import {
  convertGoogleContactDataToCsv,
  createCsvFile,
  createFile,
  createHousingReport,
  createRentReport,
  dateFromFileName,
  getWorkBook,
  parseRentXlsxFile,
  parseRosterXlsxFile,
  readDate,
} from './spreadsheet';
import {
  normalizeRentData,
  normalizeRosterData,
  transformDataForGoogleContacts,
  transformDataForHousingReport,
} from './transform-data';
import { RawRosterDataCsv, RawHouseRentDataCsv } from './types';

$dropzone.addEventListener('drop', async (event: DragEvent) => {
  event.preventDefault();
  try {
    $dropzone.classList.remove('dragging-file');

    if (event.dataTransfer.files.length > 1) {
      throw new Error('Cannot drop more than 1 file');
    } else if (event.dataTransfer.files.length === 0) {
      throw new Error('Could not find a file');
    }

    // TODO check file type?

    // parse input
    const file: File = event.dataTransfer.files.item(0);
    const workBook = await getWorkBook(file);

    try {
      const rawData = parseRosterXlsxFile(workBook);
      await organizationRosterInput(rawData, workBook);
    } catch (err) {
      const rawData = parseRentXlsxFile(workBook);
      await houseRentInput(rawData, file);
    }

    clearError();
  } catch (err) {
    displayError(err);
  }
});

async function organizationRosterInput(rawData: RawRosterDataCsv[], workBook: WorkBook) {
  const companionships = normalizeRosterData(rawData);
  const date = readDate(workBook);
  const dateStr = date ? date.format('YYYY-MM-DD') : 'Unknown Date';

  // create Zip
  const folderName = `Reports - ${dateStr}`;
  const zip = new JSZip();
  zip.folder(folderName);

  // create google contact data
  const googleContactsData = transformDataForGoogleContacts(companionships);
  const googleContactCsvString = convertGoogleContactDataToCsv(googleContactsData);
  const googleContactsFileName = `Missionary Contacts for Google Contacts - ${dateStr}.csv`;
  const csvFile: File = createCsvFile(googleContactCsvString, googleContactsFileName);
  zip.file(`${folderName}/${googleContactsFileName}`, csvFile);

  // create housing report
  const houseSheetsData = transformDataForHousingReport(companionships);
  const xlsHousingFile = createHousingReport(houseSheetsData, date);
  const housingReportName = `Housing Report - ${dateStr}.xlsx`;
  zip.file(`${folderName}/${housingReportName}`, xlsHousingFile);

  // create zip file and download
  const zipBlob: Blob = await zip.generateAsync({ type: 'blob' });
  const zipFile = createFile(zipBlob, `${folderName}.zip`, 'application/zip');
  downloadFile(zipFile);
}

async function houseRentInput(rawData: RawHouseRentDataCsv[], file: File) {
  const data = normalizeRentData(rawData);
  const dateStr = dateFromFileName(file.name);
  const xlsRentFile = createRentReport(data);
  const housingReportName = `House Rent Report - ${dateStr}.xlsx`;

  const folderName = `Rent Report - ${dateStr}`;
  const zip = new JSZip();
  zip.folder(folderName);

  zip.file(`${folderName}/${housingReportName}`, xlsRentFile);

  const zipBlob: Blob = await zip.generateAsync({ type: 'blob' });
  const zipFile = createFile(zipBlob, `${folderName}.zip`, 'application/zip');
  downloadFile(zipFile);
}

$dropzone.addEventListener('dragstart', (event) => {
  event.preventDefault();
});

$dropzone.addEventListener('dragstop', (event) => {
  event.preventDefault();
});

$dropzone.addEventListener('drag', (event) => {
  event.preventDefault();
});

$dropzone.addEventListener('dragenter', (event) => {
  event.preventDefault();
  $dropzone.classList.add('dragging-file');
});

$dropzone.addEventListener('dragleave', (event) => {
  event.preventDefault();
  $dropzone.classList.remove('dragging-file');
});

$dropzone.addEventListener('dragexit', (event) => {
  event.preventDefault();
});

document.addEventListener('dragover', (event) => {
  // prevent default to allow drop
  event.preventDefault();
});

window.addEventListener('dragover', (event) => {
  // prevent default to allow drop
  event.preventDefault();
});

window.addEventListener('drop', (event) => {
  // prevent default to allow drop
  event.preventDefault();
});
