import ExcelJS, { Style, Worksheet } from 'exceljs';
import { forEach, isEmpty } from 'lodash';
import moment from 'moment';
import * as Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { WorkBook, WorkSheet } from 'xlsx';
import { Zone } from './transform-data';
import {
  HousingRow,
  HousingSheets,
  MissionaryContactCsv,
  RawHouseRentData,
  RawHouseRentDataCsv,
  RawRosterDataCsv,
} from './types';

interface SpreadsheetFileReader extends FileReader {
  promise: Promise<void>;
}

function createFileReader(): SpreadsheetFileReader {
  const fileReader: SpreadsheetFileReader = <SpreadsheetFileReader>new FileReader();

  fileReader.promise = new Promise((resolve, reject) => {
    fileReader.onload = () => resolve();
    fileReader.onabort = () => resolve();
    fileReader.onerror = () => reject();
  });

  return fileReader;
}

async function readAsBinaryString(file: File | Buffer): Promise<string> {
  if (file instanceof Buffer) {
    return file.toString('binary');
  }

  const fileReader: SpreadsheetFileReader = createFileReader();

  fileReader.readAsBinaryString(file);

  await fileReader.promise;

  return <string>fileReader.result;
}

export async function getWorkBook(file: File): Promise<WorkBook> {
  // const workbook = new ExcelJS.Workbook();
  // console.log(await file.arrayBuffer());
  // const data = await workbook.xlsx.load(await file.arrayBuffer());
  // console.log(JSON.stringify(workbook.worksheets, null, 2));

  return XLSX.read(await readAsBinaryString(file), {
    type: 'binary',
  });
}

export function readDate(book: WorkBook): moment.Moment {
  const cellContents: string = book.Sheets[book.SheetNames[0]]['A2'].v;

  const matches = cellContents.match(/(\d{1,2} \w{3} \d{4}) *$/);

  if (!matches) {
    console.error('Could not determine date');
    return null;
  }

  const [, date] = matches;

  if (!date) {
    console.error('Could not determine date (2)');
    return null;
  }

  const m = moment(date);

  if (!m.isValid()) {
    console.error(`Found date (${date}), but it was invalid`);
    return null;
  }

  return m;
}

export function dateFromFileName(name: string): string {
  // fallback to today
  let dateStr = moment().format('YYYY-MM-DD');
  const matches = name.match(/\d{1,2}-\d{1,2}-\d{1,2}/);

  if (matches) {
    const normalizedDate = matches[0]
      .split('-')
      .map((part) => part.padStart(2, '0'))
      .join('-');
    dateStr = moment(normalizedDate, 'MM-DD-YY').format('YYYY-MM-DD');
  }

  return dateStr;
}

export function parseRosterXlsxFile(book: WorkBook): RawRosterDataCsv[] {
  const sheet: WorkSheet = book.Sheets[book.SheetNames[0]];

  let csvString: string = XLSX.utils.sheet_to_csv(sheet);

  const parts: string[] = csvString.split('\n');
  parts.shift();
  parts.shift();
  csvString = parts.join('\n');

  const { errors, data } = Papa.parse(csvString, {
    header: true,
    skipEmptyLines: true,
    trimHeaders: true,
  });

  if (!isEmpty(errors)) {
    console.error(errors);
    throw new Error('Some records had errors, and the file could not be processed.');
  }

  return data;
}

export function parseRentXlsxFile(book: WorkBook): RawHouseRentDataCsv[] {
  const sheet: WorkSheet = book.Sheets[book.SheetNames[0]];

  let csvString: string = XLSX.utils.sheet_to_csv(sheet);

  const parts: string[] = csvString.split('\n');
  parts.shift();
  parts.shift();
  parts.shift();
  parts[0] = parts[0]
    .replace('Currency,Payee', 'Currency,Payee Alt')
    .replace(
      'Payee,Payee Address,City,State/Province,Postal Code',
      'Payee,Payee Address,Payee City,Payee State/Province,Payee Postal Code',
    );
  csvString = parts.join('\n');

  const { errors, data } = Papa.parse(csvString, {
    header: true,
    skipEmptyLines: true,
    trimHeaders: true,
  });

  if (!isEmpty(errors)) {
    console.error(errors);
    throw new Error('Some records had errors, and the file could not be processed.');
  }

  while (!data[data.length - 1]['House ID'] || data[data.length - 1]['House ID'].startsWith('Confidential')) {
    data.pop();
  }

  return data;
}

export async function createHousingReport(data: HousingSheets, date: moment.Moment): Promise<Buffer> {
  const workbook = new ExcelJS.Workbook();

  forEach(data, (rows: HousingRow[], zone: keyof Zone) => {
    if (zone === 'OFFICE') {
      return;
    }

    const worksheet: Worksheet = workbook.addWorksheet(zone, {
      // headerFooter: {
      //   firstHeader: `Transfer Date: ${date.format('D MMM YYYY')}`,
      // },
      pageSetup: {
        fitToPage: true,
        orientation: 'portrait',
        showGridLines: true,
      },
    });

    const globalStyles: Partial<Style> = {
      font: {
        size: 14,
        name: 'Calibri',
      },
      alignment: {
        wrapText: true,
        vertical: 'top',
        horizontal: 'center',
      },
    };

    const headerStyle: Partial<Style> = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFF2CC' },
      },
      font: {
        color: { argb: 'FFFF0000' },
        size: 16,
        name: 'Calibri',
        bold: true,
      },
    };

    worksheet.columns = [
      { header: 'Apt #', key: 'houseName', style: globalStyles, width: 14.25 },
      { header: `${zone} ZONE`, key: 'address', style: globalStyles, width: 34 },
      { header: 'Phone #', key: 'phone', style: globalStyles, width: 18.5 },
      { header: 'Assigned Area', key: 'area', style: globalStyles, width: 25 },
      { header: 'Companionship', key: 'companionship', style: globalStyles, width: 25 },
    ];

    const origLen = rows.length;
    const rowsWithSpacing = [...rows];
    for (let i = origLen - 1; i > 0; i--) {
      // add a blank line in between entries
      rowsWithSpacing.splice(i, 0, {
        houseName: '',
        address: '',
        phone: '',
        area: '',
        companionship: '',
      });
    }

    // key at bottom
    rowsWithSpacing.push(
      {
        houseName: '',
        address: '',
        phone: '',
        area: '',
        companionship: '',
      },
      {
        houseName: '',
        address: '*ZL = Zone Leader',
        phone: '',
        area: '',
        companionship: '',
      },
      {
        houseName: '',
        address: '*STL = Sister Training Leader',
        phone: '',
        area: '',
        companionship: '',
      },
    );

    worksheet.addRows(rowsWithSpacing);

    // row at the top that mentions the transfer today... which is when this was generated, I guess
    worksheet.duplicateRow(1, 1, true);
    worksheet.getRow(1).getCell('houseName').value = 'Transfer Date';
    worksheet.getRow(1).getCell('address').value = date.format('D MMM YYYY');
    worksheet.getRow(1).getCell('phone').value = '';
    worksheet.getRow(1).getCell('area').value = '';
    worksheet.getRow(1).getCell('companionship').value = '';

    // special header row styles
    worksheet.getRow(2).eachCell((c) => (c.style = { ...c.style, ...headerStyle }));

    // bold some stuff
    worksheet.getRow(1).eachCell((c) => (c.style = { ...c.style, font: { ...c.style.font, bold: true } }));
    worksheet
      .getRow(worksheet.rowCount)
      .eachCell((c) => (c.style = { ...c.style, font: { ...c.style.font, bold: true } }));
    worksheet
      .getRow(worksheet.rowCount - 1)
      .eachCell((c) => (c.style = { ...c.style, font: { ...c.style.font, bold: true } }));
  });

  return <Buffer>await workbook.xlsx.writeBuffer();
}

export async function createRentReport(data: RawHouseRentData[]): Promise<Buffer> {
  const workbook = new ExcelJS.Workbook();

  const worksheet: Worksheet = workbook.addWorksheet('Sheet1', {
    pageSetup: {
      orientation: 'landscape',
      showGridLines: true,
      horizontalCentered: true,
      verticalCentered: true,
    },
    properties: {
      defaultRowHeight: 25,
    },
  });

  const globalStyles: Partial<Style> = {
    font: {
      size: 10,
      name: 'Arial Unicode MS',
      bold: true,
    },
    alignment: {
      wrapText: true,
      vertical: 'middle',
      horizontal: 'center',
    },
  };

  worksheet.columns = [
    { header: 'House Name', key: 'houseName', style: globalStyles, width: 14 + 7 / 8 },
    { header: 'Street Address', key: 'streetAddress', style: globalStyles, width: 49 + 7 / 8 },
    { header: 'City', key: 'city', style: globalStyles, width: 12.5 + 7 / 8 },
    { header: 'State/Province', key: 'stateProvince', style: globalStyles, width: 11.67 + 7 / 8 },
    { header: 'House Type', key: 'houseType', style: globalStyles, width: 14.5 + 7 / 8 },
    { header: 'Lease Start', key: 'leaseStart', style: globalStyles, width: 9 + 7 / 8 },
    { header: 'Lease End', key: 'leaseEnd', style: globalStyles, width: 9 + 7 / 8 },
    { header: 'Lease Type', key: 'leaseType', style: globalStyles, width: 16.5 + 7 / 8 },
    { header: 'Rent Amount', key: 'rentAmount', style: globalStyles, width: 10.5 + 7 / 8 },
    { header: 'Security Deposit', key: 'securityDeposit', style: globalStyles, width: 12.83 + 7 / 8 },
    { header: 'Payee', key: 'payee', style: globalStyles, width: 38.33 + 7 / 8 },
    { header: 'Count', key: 'count', style: globalStyles, width: 5 + 7 / 8 },
    { header: 'Capacity', key: 'capacity', style: globalStyles, width: 7 + 7 / 8 },
  ];

  worksheet.addRows(data);

  // header row
  worksheet.duplicateRow(1, 1, true);
  worksheet.getRow(1).eachCell((c) => (c.value = ''));
  worksheet.getRow(1).getCell(1).value = 'Washington Spokane Mission';
  worksheet.mergeCells('A1:M1');

  // for some reason, default height is sometimes ignored
  worksheet.eachRow((r) => (r.height = 25));

  return <Buffer>await workbook.xlsx.writeBuffer();
}

export function convertGoogleContactDataToCsv(data: MissionaryContactCsv[]): string {
  return Papa.unparse(data, { newline: '\n' });
}

export function createFile(input: string | Blob | BufferSource, filename: string, type: string): File {
  return new File([input], filename, { type });
}

export function createCsvFile(csvString: string, filename: string): File {
  return createFile(csvString, filename, 'text/csv');
}
