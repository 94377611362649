import { $error } from './elements';

export function displayError(err: Error): void {
  console.error(err);

  $error.innerText = err.message;
  $error.classList.add('has-error');
}

export function clearError(): void {
  $error.classList.remove('has-error');
}
