import {
  camelCase,
  compact,
  countBy,
  flatMap,
  forEach,
  fromPairs,
  groupBy,
  keys,
  map,
  mapKeys,
  mapValues,
  maxBy,
  sortBy,
  sum,
  uniq,
  values,
} from 'lodash';
import {
  Companionship,
  HousingRow,
  HousingSheets,
  Missionary,
  MissionaryContact,
  MissionaryContactCsv,
  RawHouseRentData,
  RawHouseRentDataCsv,
  RawRosterData,
  RawRosterDataCsv,
  Title,
} from './types';

export function normalizeRosterData(rawData: RawRosterDataCsv[]): Companionship[] {
  const niceData: RawRosterData[] = map(
    rawData,
    (datum) => <RawRosterData>(<unknown>mapKeys(datum, (v: string, k: string) => camelCase(k.replace('/', '-')))),
  );

  const groupedData = groupBy(
    niceData.filter((row) => row.lastName?.trim() !== ''),
    // (row) => makeKey(row),
    (row) => row.phone1,
  );

  const lastNameGroupings: Record<string, RawRosterData[]> = groupBy(niceData, (row) => row.lastName);

  const nameCounts: Record<string, Record<string, number>> = mapValues(lastNameGroupings, (records) =>
    countBy(records, (record) => record.firstName[0]),
  );

  return map(groupedData, (companions) => transformCompanionship(companions, nameCounts));
}

export function normalizeRentData(rawData: RawHouseRentDataCsv[]): RawHouseRentData[] {
  const niceData: RawHouseRentData[] = map(
    rawData,
    (datum) =>
      <RawHouseRentData>(<unknown>mapKeys(datum, (v: string, k: string) => camelCase(k.replace(/[\/*]/g, '-')))),
  );

  return niceData;
}

export function transformDataForGoogleContacts(data: Companionship[]): MissionaryContactCsv[] {
  const csvData: MissionaryContact[] = data.map((companionship) => ({
    lastName: companionship.companions.map((c) => `${c.uniqueName} (${c.position})`).join(' & '),
    emails: [],
    phoneNumbers: companionship.phoneNumbers.map((phoneNumber: string) => ({
      phoneNumber,
    })),
    addresses: [companionship.address],
    organizations: [
      {
        name: makeKey(companionship),
      },
    ],
    customFields: [
      {
        label: 'Type',
        value: companionship.type,
      },
    ],
    group: 'Current Roster ::: * My Contacts',
  }));

  const paddedNiceData: MissionaryContact[] = padData(csvData);

  return paddedNiceData.map((row) => googleContactify(row));
}

export function transformDataForHousingReport(data: Companionship[]): HousingSheets {
  const compGroups: { [zone: string]: Companionship[] } = groupBy(data, (comp) => comp.zone || 'UNASSIGNED ZONE');

  const sheets: HousingSheets = {};

  const sortedCompGroups = mapValues(compGroups, (comps) => sortBy(comps, (comp) => comp.houseName));

  forEach(sortedCompGroups, (comps: Companionship[], zone: keyof Zone) => {
    sheets[zone] = comps.map((comp: Companionship) => {
      let address = comp.address.street;

      if (comp.address.extendedAddress) {
        address += ' ' + comp.address.extendedAddress;
      }

      address += `\n${comp.address.city}, ${comp.address.region}`;

      if (address.trim() === ',') {
        address = '';
      }

      return {
        houseName: comp.houseName,
        address: address,
        phone: comp.phoneNumbers.join('\n'),
        area: comp.area
          .split('/')
          .map((a) => (a || '').trim())
          .join('\n'),
        companionship: comp.companions
          .map((c) => {
            let conditionalPosition = '';

            if (/stl[\dt]/i.test(c.position)) {
              conditionalPosition = ' - STL';
            } else if (/zl[\d]/i.test(c.position)) {
              conditionalPosition = ' - ZL';
            }

            return `${c.title} ${c.uniqueName}${conditionalPosition}`;
          })
          .join('\n'),
      };
    });
  });

  const alphabetizedSheets: HousingSheets = {};
  keys(sheets)
    .sort()
    .forEach((zone: keyof Zone) => (alphabetizedSheets[zone] = sheets[zone]));

  return alphabetizedSheets;
}

function transformCompanionship(
  rawData: RawRosterData[],
  nameCounts: Record<string, Record<string, number>>,
): Companionship {
  const missionaries: Missionary[] = rawData.map((row) => {
    let uniqueName: string = row.lastName;

    if (sum(values(nameCounts[uniqueName])) > 1) {
      if (nameCounts[uniqueName][row.firstName[0]] > 1) {
        uniqueName += ', ' + row.firstName;
      } else {
        uniqueName += ', ' + row.firstName[0];
      }
    }

    let title: Title = null;

    if (/elder/i.test(row.type)) {
      title = 'Elder';
    } else if (/sister/i.test(row.type)) {
      title = 'Sister';
    }

    return {
      firstName: row.firstName,
      lastName: row.lastName,
      middleName: row.middleName,
      uniqueName: `${uniqueName}`,
      position: row.position,
      title,
    };
  });

  const uniquePhones: string[] = uniq(
    compact(flatMap(rawData, (row) => [row.phone1, row.phone2, row.phone3, row.phone4])),
  );

  return {
    companions: missionaries,
    emails: rawData.map((row) => row.email),
    phoneNumbers: uniquePhones,
    address: {
      type: 'Home', // label
      street: rawData[0].street,
      city: rawData[0].city,
      region: rawData[0].stateProvince,
      postalCode: rawData[0].postalCode,
      country: rawData[0].country,
    },
    type: rawData[0].type,
    zone: rawData[0].zone,
    district: rawData[0].district,
    area: rawData[0].area,
    houseName: rawData[0].houseName,
  };
}

function googleContactify(contact: MissionaryContact): MissionaryContactCsv {
  const googleContact: MissionaryContactCsv = {
    'Family Name': contact.lastName,
    'Given Name': contact.firstName,
    'Additional Name': contact.middleName,
    'Group Membership': contact.group,

    ...fromPairs(
      flatMap(contact.organizations, (org, i) => [
        [`Organization ${i + 1} - Name`, org.name],
        [`Organization ${i + 1} - Type`, org.type],
      ]),
    ),

    ...fromPairs(
      flatMap(contact.phoneNumbers, (phoneNumber, i) => [
        [`Phone ${i + 1} - Type`, phoneNumber.type],
        [`Phone ${i + 1} - Value`, phoneNumber.phoneNumber],
      ]),
    ),

    ...fromPairs(
      flatMap(contact.emails, (email, i) => [
        [`Email ${i + 1} - Type`, email.type],
        [`Email ${i + 1} - Value`, email.email],
      ]),
    ),

    ...fromPairs(
      flatMap(contact.addresses, (address, i) => [
        [`Address ${i + 1} - Type`, address.type],
        [`Address ${i + 1} - Street`, address.street],
        [`Address ${i + 1} - City`, address.city],
        [`Address ${i + 1} - PO Box`, address.poBox],
        [`Address ${i + 1} - Region`, address.region],
        [`Address ${i + 1} - Postal Code`, address.postalCode],
        [`Address ${i + 1} - Country`, address.country],
        [`Address ${i + 1} - Extended Address`, address.extendedAddress],
      ]),
    ),

    ...fromPairs(
      flatMap(contact.customFields, (field, i) => [
        [`Custom Field ${i + 1} - Type`, field.label],
        [`Custom Field ${i + 1} - Value`, field.value],
      ]),
    ),
  };

  return mapValues(googleContact, (val) => (val === undefined ? '' : val));
}

function padData(niceData: MissionaryContact[]): MissionaryContact[] {
  const maxEmails = (maxBy(niceData, (row) => (row.emails || []).length).emails || []).length;
  const maxPhoneNumbers = (maxBy(niceData, (row) => (row.phoneNumbers || []).length).phoneNumbers || []).length;
  const maxAddresses = (maxBy(niceData, (row) => (row.addresses || []).length).addresses || []).length;
  const maxOrganizations = (maxBy(niceData, (row) => (row.organizations || []).length).organizations || []).length;
  const maxCustomFields = (maxBy(niceData, (row) => (row.customFields || []).length).customFields || []).length;

  return niceData.map((row) => {
    row = pad(row, 'emails', maxEmails);
    row = pad(row, 'phoneNumbers', maxPhoneNumbers);
    row = pad(row, 'addresses', maxAddresses);
    row = pad(row, 'organizations', maxOrganizations);
    row = pad(row, 'customFields', maxCustomFields);

    return row;
  });
}

function pad(
  row: MissionaryContact,
  key: keyof Pick<MissionaryContact, 'emails' | 'phoneNumbers' | 'addresses' | 'organizations' | 'customFields'>,
  len: number,
): MissionaryContact {
  while (row[key].length < len) {
    row[key].push(<any>{});
  }

  return row;
}

const zoneLookup = {
  "COEUR D'ALENE": 'CD',
  MOSCOW: 'M',
  LEWISTON: 'L',
  'MT. SPOKANE': 'MT',
  'SPOKANE WEST': 'SW',
  OFFICE: 'OF',
  'SPOKANE EAST': 'SE',
  SANDPOINT: 'SP',
  'HAYDEN LAKE': 'HL',
  COLVILLE: 'C',
  'SPOKANE VALLEY': 'SV',
  'SPOKANE NORTH': 'SN',
  SPOKANE: 'S',
};

const zoneReverseLookup = {
  CD: "COEUR D'ALENE",
  M: 'MOSCOW',
  L: 'LEWISTON',
  MT: 'MT. SPOKANE',
  SW: 'SPOKANE WEST',
  OF: 'OFFICE',
  SE: 'SPOKANE EAST',
  SP: 'SANDPOINT',
  HL: 'HAYDEN LAKE',
  C: 'COLVILLE',
  SV: 'SPOKANE VALLEY',
  SN: 'SPOKANE NORTH',
  S: 'SPOKANE',
};

export type Zone = typeof zoneLookup;
export type ZoneAbbrev = typeof zoneReverseLookup;

function zoneAbbreviation(zone: keyof Zone): string {
  return zoneLookup[zone];
}

function zoneName(abbr: keyof ZoneAbbrev): string {
  return zoneReverseLookup[abbr];
}

function makeKey(row: RawRosterData | Companionship): string {
  return `${zoneAbbreviation(row.zone)}, ${row.district}, ${row.area}, ${row.houseName}`;
}
